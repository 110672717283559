export var GroupType;
(function (GroupType) {
    GroupType["NoGroup"] = "NO GROUP";
    GroupType["Regular"] = "REGULAR";
    GroupType["System"] = "SYSTEM";
})(GroupType || (GroupType = {}));
export const isGroupProps = (r) => (r?.id !== undefined
    && r?.name !== undefined
// && (r as GroupProps).type !== undefined
);
export var CategoryType;
(function (CategoryType) {
    CategoryType["Regular"] = "REGULAR";
    CategoryType["Unassigned"] = "UNASSIGNED";
    CategoryType["FundingPool"] = "FUNDING POOL";
    CategoryType["AccountTransfer"] = "ACCOUNT TRANSFER";
    CategoryType["Loan"] = "LOAN";
    CategoryType["Bill"] = "BILL";
    CategoryType["Goal"] = "GOAL";
})(CategoryType || (CategoryType = {}));
export const isCategoryBalance = (r) => (r.id !== undefined
    && r.balance !== undefined);
export const isAddCategoryResponse = (r) => (r.id !== undefined
    && r.balance !== undefined
    && r.name !== undefined
    && r.type !== undefined);
export const isUpdateCategoryResponse = (r) => (r.name !== undefined);
export const isUpdateLoanResponse = (r) => (r.name !== undefined
    && r.balance !== undefined
    && r.rate !== undefined);
export const isUpdateFundingCategoryResponse = (r) => (r.fundingAmount !== undefined
    && r.id !== undefined);
export const isCategoryProps = (r) => (r?.id !== undefined
    && r?.name !== undefined
    && r?.balance !== undefined);
export const isAccountProps = (r) => (r.id !== undefined
    && r.name !== undefined
    && r.tracking !== undefined
    // && (r as AccountProps).syncDate !== undefined
    && r.balance !== undefined);
export const isInstitutionProps = (r) => (r.id !== undefined
    && r.name !== undefined
    && r.accounts !== undefined);
export const isInstitutionsResponse = (r) => (Array.isArray(r)
    && (r.length === 0
        || isInstitutionProps(r[0])));
export const isAddInstitutionResponse = (r) => (r.id !== undefined
    && r.name !== undefined
    // && (r as AddInstitutionResponse).offline !== undefined
    && r.accounts !== undefined
    && Array.isArray(r.accounts)
// && (r as AddInstitutionResponse).categories !== undefined
// && Array.isArray((r as AddInstitutionResponse).categories)
);
export var TransactionType;
(function (TransactionType) {
    TransactionType[TransactionType["REGULAR_TRANSACTION"] = 0] = "REGULAR_TRANSACTION";
    TransactionType[TransactionType["TRANSFER_TRANSACTION"] = 1] = "TRANSFER_TRANSACTION";
    TransactionType[TransactionType["FUNDING_TRANSACTION"] = 2] = "FUNDING_TRANSACTION";
    TransactionType[TransactionType["REBALANCE_TRANSACTION"] = 3] = "REBALANCE_TRANSACTION";
    TransactionType[TransactionType["STARTING_BALANCE"] = 4] = "STARTING_BALANCE";
    TransactionType[TransactionType["MANUAL_TRANSACTION"] = 5] = "MANUAL_TRANSACTION";
})(TransactionType || (TransactionType = {}));
export const isTransactionProps = (r) => (r !== undefined);
export const isCategoryLoanResponse = (r) => (r !== undefined && r !== null
    && r.transactions !== undefined
    && r.balance !== undefined);
export const isLoanUpdateProps = (r) => ((r.name !== undefined
    && isCategoryLoanResponse(r.loan)));
export const isLoanTransactionsResponse = (r) => (r !== undefined && r !== null
    && Array.isArray(r)
    && (r.length === 0 || (r[0].id !== undefined
        // && (r as LoanTransactionsResponse)[0].amount !== undefined
        && r[0].principle !== undefined)));
export const isPendingTransactionsResponse = (r) => (r !== undefined && r !== null
    && Array.isArray(r) && (r.length === 0 || isTransactionProps(r[0])));
export var RequestErrorCode;
(function (RequestErrorCode) {
    RequestErrorCode["INCORRECT_VERSION"] = "INCORRECT_VERSION";
})(RequestErrorCode || (RequestErrorCode = {}));
export const isErrorResponse = (r) => (r.errors !== undefined);
export const isCategoryUpdateResponse = (r) => (r.name !== undefined);
export const isUpdateCategoryTransferResponse = (r) => (r.balances !== undefined
    && Array.isArray(r.balances)
    && (r.balances.length === 0
        || isCategoryBalance(r.balances[0])));
export const isDeleteTransactionResponse = (r) => (r.categories !== undefined
    && Array.isArray(r.categories)
    && (r.categories.length === 0
        || isCategoryBalance(r.categories[0])));
export const isInsertCategoryTransferResponse = (r) => (r.balances !== undefined
    && Array.isArray(r.balances)
    && (r.balances.length === 0
        || isCategoryBalance(r.balances[0])));
export const isGroupsResponse = (r) => (Array.isArray(r)
    && (r.length === 0 || isGroupProps(r[0])));
export const isAccountsResponse = (r) => (Array.isArray(r)
    && (r.length === 0 || isAccountProps(r[0])));
export const isAddAccountsResponse = (r) => (r.accounts !== undefined
    && (r.accounts.length === 0 || isAccountProps(r.accounts[0])));
export const isFundingPlanCategoryProps = (r) => (r.id !== undefined
    && r.categoryId !== undefined
    && r.amount !== undefined
    && r.useGoal !== undefined
    && r.goalDate !== undefined
    && r.recurrence !== undefined);
export const isFundingPlanResponse = (r) => (r.id !== undefined
    && (Array.isArray(r.categories))
    && (r.categories.length === 0 || isFundingPlanCategoryProps(r.categories[0])));
export const isFundingPlanDetailsProps = (r) => (r.id !== undefined
    && r.history !== undefined
    && r.categories !== undefined);
export const isFundingPlanProps = (r) => (r.id !== undefined
    && r.name !== undefined);
export const isFundingPlansResponse = (r) => (Array.isArray(r)
    && (r.length === 0
        || isFundingPlanProps(r[0])));
export const isCategoryFundingProps = (r) => (r.amount !== undefined
    && r.categoryId !== undefined);
export const isLinkTokenResponse = (r) => (r.linkToken !== undefined);
export const isBalanceProps = (r) => (r.balance !== undefined);
export const isBalancesResponse = (r) => (r.length === 0
    || isBalanceProps(r[0]));
export const isAddBalanceResponse = (r) => (r.balance !== undefined);
export const isUpdateBalanceResponse = (r) => (r.balance !== undefined
    && r.date !== undefined);
export const isUserProps = (r) => (r.username !== undefined
    && r.email !== undefined);
export const isAddTransactionResponse = (r) => (r.categories !== undefined
    && Array.isArray(r.categories));
export const isCategoryTreeBalanceProps = (r) => (r.id !== undefined
    && r.balance !== undefined);
export const isCategoryTreeBalanceResponse = (r) => (Array.isArray(r)
    && isCategoryTreeBalanceProps(r[0]));
export const isDeleteInstitutionResponse = (r) => (Array.isArray(r)
    && (r.length === 0 || isCategoryBalance(r[0])));
export const isDeleteAccountResponse = (r) => (Array.isArray(r)
    && (r.length === 0 || isCategoryBalance(r[0])));
export var PendingQueryFlag;
(function (PendingQueryFlag) {
    PendingQueryFlag[PendingQueryFlag["NoPending"] = 0] = "NoPending";
    PendingQueryFlag[PendingQueryFlag["OnlyPending"] = 1] = "OnlyPending";
    PendingQueryFlag[PendingQueryFlag["WithPending"] = 2] = "WithPending";
})(PendingQueryFlag || (PendingQueryFlag = {}));
