/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field, } from 'formik';
import { makeUseModal } from '@mortvola/usemodal';
import { FormModal, FormError, setFormErrors, FormField, FormCheckbox, } from '@mortvola/forms';
import { DateTime } from 'luxon';
import { isGroup } from '../../State/Group';
import { useStores } from '../../State/Store';
import AmountInput from '../../AmountInput';
import styles from './CategoryDialog.module.scss';
import { CategoryType, GroupType } from '../../../common/ResponseTypes';
import CategorySpread from '../../CategorySpread/CategorySpread';
const CategoryDialog = ({ setShow, category = null, type = CategoryType.Regular, }) => {
    const { categoryTree } = useStores();
    const { unassignedCat, budget: { fundingPoolCat } } = categoryTree;
    if (!unassignedCat || !fundingPoolCat) {
        throw new Error('Unassigned is not defined or null');
    }
    const [categoryType, setCategoryType] = React.useState((category?.type ?? type) ?? CategoryType.Regular);
    const handleCategoryTypeChange = (newType) => {
        setCategoryType(newType);
    };
    const handleSubmit = async (values, bag) => {
        const { setErrors } = bag;
        let errors = null;
        const selectedGroup = (categoryTree.budget.children.find((g) => isGroup(g) && g.id === parseInt(values.groupId, 10))
            ?? categoryTree.noGroupGroup);
        if (selectedGroup === null || !isGroup(selectedGroup)) {
            throw new Error(`group is not a group: ${selectedGroup}`);
        }
        if (category) {
            errors = await category.update({
                type: values.type,
                name: values.name,
                fundingAmount: parseFloat(values.fundingAmount),
                includeFundingTransfers: values.includeFundingTransfers,
                group: selectedGroup,
                recurrence: parseInt(values.recurrence, 10),
                useGoal: values.type !== CategoryType.Regular,
                goalDate: DateTime.fromISO(values.goalDate),
                fundingCategories: values.fundingCategories,
            });
        }
        else {
            errors = await selectedGroup.addCategory({
                type: values.type,
                name: values.name,
                fundingAmount: parseFloat(values.fundingAmount),
                includeFundingTransfers: values.includeFundingTransfers,
                group: selectedGroup,
                recurrence: parseInt(values.recurrence, 10),
                useGoal: values.type !== CategoryType.Regular,
                goalDate: DateTime.fromISO(values.goalDate),
                fundingCategories: values.fundingCategories,
            });
        }
        if (errors) {
            setFormErrors(setErrors, errors);
        }
        else {
            setShow(false);
        }
    };
    const handleValidate = (values) => {
        const errors = {};
        if (values.name === '') {
            errors.name = 'The category name must not be blank.';
        }
        return errors;
    };
    const handleDelete = async (bag) => {
        const { setErrors } = bag;
        if (!category) {
            throw new Error('category is null or undefined');
        }
        const errors = await category.delete();
        if (errors) {
            setFormErrors(setErrors, errors);
        }
        else {
            setShow(false);
        }
    };
    const title = () => {
        if (category) {
            return 'Edit Category';
        }
        return 'Add Category';
    };
    const populateGroups = () => {
        const options = [];
        if (categoryTree.noGroupGroup !== null) {
            options.push(React.createElement("option", { key: "nogroup", value: categoryTree.noGroupGroup.id }, "No Group"));
        }
        let stack = [...categoryTree.budget.children];
        while (stack.length > 0) {
            const group = stack[0];
            stack = stack.slice(1);
            if (isGroup(group) && group.type === GroupType.Regular) {
                options.push(React.createElement("option", { key: group.id, value: group.id }, group.name));
                stack = [
                    ...group.children,
                    ...stack,
                ];
            }
        }
        return options;
    };
    const categoryTypeClass = () => ([CategoryType.Bill, CategoryType.Goal].includes(categoryType) ? styles.bill : '');
    const getGoalDate = (goalDate, recurrence = 1) => {
        if (goalDate) {
            let adjustedGoal = goalDate;
            const now = DateTime.now().startOf('month');
            const monthDiff = goalDate.startOf('month').diff(now, 'months').months;
            if (monthDiff < 0) {
                const numPeriods = Math.ceil(-monthDiff / recurrence);
                adjustedGoal = goalDate.plus({ months: numPeriods * recurrence });
            }
            return adjustedGoal.toISODate() ?? '';
        }
        return '';
    };
    const initialCategories = (categories) => {
        if (!categories || categories.length === 0) {
            return [{
                    id: -1, categoryId: fundingPoolCat.id, amount: 100, percentage: true,
                }];
        }
        return categories.map((c) => ({
            id: c.id, categoryId: c.categoryId, amount: c.amount, percentage: c.percentage,
        }));
    };
    return (React.createElement(FormModal, { setShow: setShow, initialValues: {
            type: categoryType,
            name: category && category.name ? category.name : '',
            groupId: category?.group.id.toString() ?? '',
            fundingAmount: category?.fundingAmount.toString() ?? '0',
            includeFundingTransfers: category?.includeFundingTransfers ?? true,
            recurrence: category?.recurrence.toString() ?? '1',
            goalDate: getGoalDate(category?.goalDate, category?.recurrence),
            fundingCategories: initialCategories(category?.fundingCategories),
            // monthlyExpenses: category ? category.monthlyExpenses : false,
        }, validate: handleValidate, onSubmit: handleSubmit, onDelete: category ? handleDelete : null, title: title(), formId: "catDialogForm" }, (formikProps) => (React.createElement("div", null,
        React.createElement("div", { className: styles.wrapper },
            React.createElement("label", { className: styles.type },
                "Type:",
                React.createElement(Field, { className: "form-control", name: "type" }, ({ field, form }) => (React.createElement("select", { className: "form-control", name: "type", value: field.value, onChange: (v) => {
                        handleCategoryTypeChange(v.target.value);
                        form.setFieldValue(field.name, v.target.value);
                    } },
                    React.createElement("option", { value: "REGULAR" }, "Category"),
                    React.createElement("option", { value: "BILL" }, "Bill"),
                    React.createElement("option", { value: "GOAL" }, "Goal"))))),
            React.createElement(FormField, { name: "name", label: "Name:" }),
            React.createElement("label", { className: styles.group },
                "Group:",
                React.createElement(Field, { className: "form-control", name: "groupId" }, ({ field, form }) => (React.createElement("select", { className: "form-control", name: field.name, value: field.value, onChange: (v) => {
                        form.setFieldValue(field.name, v.target.value);
                    } }, populateGroups()))),
                React.createElement(FormError, { name: "group" }))),
        React.createElement("div", { className: styles.divider }),
        React.createElement("div", { className: `${categoryTypeClass()}` },
            React.createElement("div", { className: styles.fundingTitle }, "Funding Settings"),
            React.createElement("div", { className: `${styles.layout}` },
                React.createElement("label", { className: `${styles.amount}` },
                    "Amount:",
                    React.createElement(Field, { type: "text", className: "form-control", name: "fundingAmount", as: AmountInput }),
                    React.createElement(FormError, { name: "fundingAmount" }))),
            formikProps.values.type !== CategoryType.Bill
                ? (React.createElement(FormCheckbox, { name: "includeFundingTransfers", label: "Add Funding Transfers to Funding Amount." }))
                : null,
            React.createElement("div", { className: styles.layout2 },
                React.createElement("label", { className: styles.goalDate },
                    "Date Due:",
                    React.createElement(Field, { type: "date", className: `form-control ${styles.date}`, name: "goalDate" }),
                    React.createElement(FormError, { name: "goalDate" })),
                React.createElement("label", { className: styles.recurrence },
                    "Recurrence:",
                    React.createElement(Field, { type: "number", min: "1", className: "form-control", name: "recurrence" }),
                    React.createElement(FormError, { name: "recurrence" }))),
            formikProps.values.type === CategoryType.Bill
                ? (React.createElement(CategorySpread, { name: "fundingCategories", categories: formikProps.values.fundingCategories, title: "Categories Funded from:", types: [CategoryType.Regular, CategoryType.Goal, CategoryType.FundingPool] }))
                : null)))));
};
export const useCategoryDialog = makeUseModal(CategoryDialog);
export default CategoryDialog;
