import React from 'react';
import { observer } from 'mobx-react-lite';
import Amount from '../../Amount';
import EditButton from './EditButton';
import { CategoryType } from '../../../common/ResponseTypes';
const Category = observer(({ category, selected, onCategorySelected, }) => {
    const handleClick = () => {
        onCategorySelected(category);
    };
    let className = 'cat-list-cat';
    if (selected) {
        className += ' selected';
    }
    let barClassName = 'cat-element-bar';
    if (category.type !== CategoryType.Loan && category.type !== CategoryType.Regular) {
        barClassName += ' system';
    }
    return (React.createElement("div", { className: className, onClick: handleClick },
        React.createElement("div", { className: barClassName },
            [CategoryType.Regular, CategoryType.Loan, CategoryType.Bill, CategoryType.Goal].includes(category.type)
                ? React.createElement(EditButton, { category: category })
                : null,
            React.createElement("div", { className: "cat-list-name" }, category.name)),
        React.createElement(Amount, { className: "cat-list-amt", amount: category.balance })));
});
export default Category;
