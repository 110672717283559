import React from 'react';
import { observer } from 'mobx-react-lite';
import Buttons from './GroupButtons';
import Category from './Category';
import { isCategory } from '../../State/Category';
const Group = observer(({ group, onCategorySelected, selectedCategory = null, }) => (React.createElement("div", { className: "cat-list-group" },
    React.createElement("div", { className: "group-element-bar" },
        React.createElement(Buttons, { group: group }),
        React.createElement("div", { className: "group-name" }, group.name)),
    group.children.map((category) => (isCategory(category)
        ? (React.createElement(Category, { key: category.name, category: category, group: group, onCategorySelected: onCategorySelected, selected: selectedCategory === category }))
        : (React.createElement(Group, { key: category.name, group: category, onCategorySelected: onCategorySelected })))))));
export default Group;
