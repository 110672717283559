import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMatch, useNavigate, useParams, useResolvedPath, } from 'react-router-dom';
import { useStores } from '../../State/Store';
import { isGroup } from '../../State/Group';
import Group from './Group';
import SystemCategory from './SystemCategory';
import Category from './Category';
import styles from './CategoryView.module.scss';
import useMediaQuery from '../../MediaQuery';
import RemoteDataManager from '../../RemoteDataManager';
import DesktopView from '../../DesktopView';
import MobileView from '../../MobileView';
import { GroupType } from '../../../common/ResponseTypes';
const CategoryView = observer(() => {
    const navigate = useNavigate();
    const params = useParams();
    const rebalancesPath = useResolvedPath('rebalances');
    const rebalancesMatch = useMatch({ path: rebalancesPath.pathname, end: true });
    const { categoryTree, uiState } = useStores();
    const { isMobile } = useMediaQuery();
    const handleCategorySelected = (category) => {
        navigate(category.id.toString());
    };
    const handleRebalancesClick = () => {
        navigate('rebalances');
    };
    React.useEffect(() => {
        if (categoryTree.initialized) {
            if (params.categoryId !== undefined) {
                const category = categoryTree.getCategory(parseInt(params.categoryId, 10));
                if (category) {
                    uiState.selectCategory(category);
                }
                else {
                    // category wasn't found for the categoryId. Navigate back home.
                    navigate('');
                }
            }
            else if (rebalancesMatch) {
                uiState.selectCategory(null);
            }
            else if (!isMobile) {
                uiState.selectCategory(categoryTree.unassignedCat);
            }
            else {
                uiState.selectCategory(null);
            }
        }
    }, [categoryTree, isMobile, navigate, params.categoryId, rebalancesMatch, uiState]);
    const renderSystemCategories = () => {
        let rebalancesClassName = 'cat-list-cat system';
        if (rebalancesMatch) {
            rebalancesClassName += ' selected';
        }
        return (React.createElement("div", { className: styles.system },
            React.createElement(SystemCategory, { category: categoryTree.unassignedCat, onCategorySelected: handleCategorySelected }),
            React.createElement(SystemCategory, { category: categoryTree.budget.fundingPoolCat, onCategorySelected: handleCategorySelected }),
            React.createElement(SystemCategory, { category: categoryTree.accountTransferCat, onCategorySelected: handleCategorySelected }),
            React.createElement("div", { className: rebalancesClassName, onClick: handleRebalancesClick }, "Category Transfers")));
    };
    const renderCategories = () => (React.createElement("div", { className: styles.categories }, categoryTree.budget.children.map((group) => {
        if (isGroup(group)) {
            if (group.type === GroupType.Regular) {
                return (React.createElement(Group, { key: group.name, group: group, onCategorySelected: handleCategorySelected, selectedCategory: uiState.selectedCategory }));
            }
            return null;
        }
        if (categoryTree.noGroupGroup === null) {
            throw new Error('no group is null');
        }
        return (React.createElement(Category, { key: `${group.id}`, category: group, group: categoryTree.noGroupGroup, onCategorySelected: handleCategorySelected, selected: uiState.selectedCategory === group }));
    })));
    return (React.createElement(React.Fragment, null,
        React.createElement(DesktopView, null,
            React.createElement("div", { className: styles.categoriesWrapper },
                renderSystemCategories(),
                React.createElement(RemoteDataManager, { data: categoryTree }, renderCategories()))),
        React.createElement(MobileView, null,
            React.createElement(RemoteDataManager, { data: categoryTree, className: styles.categoriesWrapper },
                renderSystemCategories(),
                renderCategories()))));
});
export default CategoryView;
