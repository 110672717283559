/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field, } from 'formik';
import { makeUseModal } from '@mortvola/usemodal';
import { FormModal, FormError, setFormErrors } from '@mortvola/forms';
import { useStores } from '../../State/Store';
import { GroupType } from '../../../common/ResponseTypes';
import styles from './GroupDialog.module.scss';
import { isGroup } from '../../State/Group';
const GroupDialog = ({ setShow, group, }) => {
    const { categoryTree } = useStores();
    const handleSubmit = async (values, formikHelpers) => {
        const { setErrors } = formikHelpers;
        let errors = null;
        if (group) {
            errors = await group.update({ name: values.name, parentGroupId: values.parentGroupId ?? null });
        }
        else {
            errors = await categoryTree.addGroup(values.name);
        }
        if (errors) {
            setFormErrors(setErrors, errors);
        }
        else {
            setShow(false);
        }
    };
    const handleValidate = (values) => {
        const errors = {};
        if (values.name === '') {
            errors.name = 'The group name must not be blank.';
        }
        return errors;
    };
    const handleDelete = async (bag) => {
        const { setErrors } = bag;
        if (group) {
            const errors = await group.delete();
            if (errors) {
                setFormErrors(setErrors, errors);
            }
            else {
                setShow(false);
            }
        }
    };
    const title = () => {
        if (group) {
            return 'Edit Group';
        }
        return 'Add Group';
    };
    const populateGroups = () => {
        const options = [];
        if (categoryTree.noGroupGroup !== null) {
            options.push(React.createElement("option", { key: "nogroup", value: categoryTree.noGroupGroup.id }, "None"));
        }
        let stack = [...categoryTree.budget.children];
        while (stack.length > 0) {
            const g = stack[0];
            stack = stack.slice(1);
            if (isGroup(g) && g.type === GroupType.Regular) {
                if (group === undefined || g.id !== group.id) {
                    options.push(React.createElement("option", { key: g.id, value: g.id }, g.name));
                }
                stack = [
                    ...g.children,
                    ...stack,
                ];
            }
        }
        return options;
    };
    return (React.createElement(FormModal, { setShow: setShow, initialValues: {
            name: group?.name ?? '',
            parentGroupId: group?.group.id ?? null,
        }, title: title(), formId: "GroupDialogForm", validate: handleValidate, onSubmit: handleSubmit, onDelete: group ? handleDelete : null },
        React.createElement("div", { className: styles.layout },
            React.createElement("label", null,
                "Group:",
                React.createElement(Field, { type: "text", className: "form-control", name: "name" }),
                React.createElement(FormError, { name: "name" })),
            React.createElement("label", null,
                "Parent Group:",
                React.createElement(Field, { className: "form-control", name: "parentGroupId" }, ({ field, form }) => (React.createElement("select", { className: "form-control", name: field.name, value: field.value, onChange: (v) => {
                        form.setFieldValue(field.name, v.target.value);
                    } }, populateGroups()))),
                React.createElement(FormError, { name: "group" })))));
};
export const useGroupDialog = makeUseModal(GroupDialog);
export default GroupDialog;
