import React from 'react';
import IconButton from '../../IconButton';
import { useGroupDialog } from './GroupDialog';
import { isGroup } from '../../State/Group';
import styles from './GroupButton.module.scss';
import { GroupType } from '../../../common/ResponseTypes';
const GroupButtons = ({ group }) => {
    const [GroupDialog, showGroupDialog] = useGroupDialog();
    const renderEditButton = () => {
        if (group.type === GroupType.Regular && isGroup(group)) {
            return (React.createElement(React.Fragment, null,
                React.createElement(IconButton, { icon: "edit", onClick: showGroupDialog, className: styles.groupButton }),
                React.createElement(GroupDialog, { group: group })));
        }
        return null;
    };
    return (React.createElement(React.Fragment, null, renderEditButton()));
};
export default GroupButtons;
