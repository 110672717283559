import React from 'react';
import { Field, FieldArray, useField, } from 'formik';
import { FormField } from '@mortvola/forms';
import { Button } from 'react-bootstrap';
import styles from './CategorySpread.module.scss';
import CategoryInput from '../CategoryInput/CategoryInput';
import AmountInput from '../AmountInput';
import IconButton from '../IconButton';
import { useStores } from '../State/Store';
const FormCategoryInput = ({ name, types }) => {
    const [field, , helpers] = useField(name);
    const handleCategoryChange = (category) => {
        helpers.setValue(category.id);
    };
    return (React.createElement(CategoryInput, { name: name, categoryId: parseInt(field.value, 10), className: "form-control", onCategoryChange: handleCategoryChange, types: types }));
};
const CategorySpread = ({ name, categories, title, types, }) => {
    const { categoryTree: { unassignedCat } } = useStores();
    if (!unassignedCat) {
        throw new Error('unassignedCat is not set');
    }
    return (React.createElement("label", { className: styles.categoriesLayout },
        title,
        React.createElement(FieldArray, { name: name }, (arrayHelpers) => (categories.map((c, i) => (
        // eslint-disable-next-line react/no-array-index-key
        React.createElement("div", { key: i, className: styles.categoryLayout },
            React.createElement(Field, { name: `${name}[${i}].categoryId`, as: FormCategoryInput, style: { marginTop: 0 }, types: types }),
            React.createElement(FormField, { as: AmountInput, name: `${name}[${i}].amount`, style: { marginTop: 0 } }),
            React.createElement(Field, { name: `${name}[${i}].percentage` }, ({ field, form }) => (React.createElement(Button, { ...field, value: field.value.toString(), className: styles.categoryPercentageToggle, onClick: () => (field.value === true
                    ? form.setFieldValue(field.name, false)
                    : form.setFieldValue(field.name, true)) }, field.value === true
                ? '%'
                : '$'))),
            React.createElement(IconButton, { icon: "plus", onClick: () => arrayHelpers.insert(i + 1, {
                    id: -1, categoryId: unassignedCat.id, amount: 0, percentage: true,
                }) }),
            React.createElement(IconButton, { icon: "minus", onClick: () => {
                    if (categories.length > 1) {
                        arrayHelpers.remove(i);
                    }
                } }))))))));
};
export default CategorySpread;
