import React from 'react';
const CategorySelectorGroup = ({ group, children, }) => {
    const handleClick = (event) => {
        event.stopPropagation();
    };
    if (group.children.length > 0) {
        return (React.createElement("div", { className: "cat-list-item", onClick: handleClick },
            group.name,
            children));
    }
    return null;
};
export default CategorySelectorGroup;
